/**
 * Redirect to a specific locale
 *
 * Use this composable, if your page does not have any slug options.
 * When using slugs, use `useRedirectToLocaleSlug` composable instead.
 */
export default () => {
  const switchLocalePath = useSwitchLocalePath();

  const redirect = async (targetLocale = 'en') => {
    const locPath = switchLocalePath(targetLocale);
    if (locPath != null) {
      await navigateTo(locPath);
    }
  };

  /**
   * Redirects to the default locale, if the current locale is not the default locale.
   *
   * If we are already on the default locale, nothing will happen.
   */
  const redirectToDefaultLocale = () => redirect('en');

  return {
    redirect,
    redirectToDefaultLocale,
  };
};
